import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BookEntry from '../components/BookEntry'
import Content, { HTMLContent } from '../components/Content'

import SEO from "../components/SEO"

export const BookclubPageTemplate = ({ featured, content, contentComponent }) => 
{
  const PostContent = contentComponent || Content
  return (
  <div className="content">
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns is-multiline is-gapless">
            <div className="column is-12">
              <BookEntry gridItems={featured} />
            </div>
            <div className="column" >
              <PostContent  content={content} />
            </div>
            
          </div>
          <a className="button is-primary" href="mailto:jawseefnetwork@outlook.com?subject=Request to Join Bookclub&body=I would like to join the bookclub." role="button">Join Bookclub</a>
        </div>
      </div>
    </section>
  </div>
  )}

BookclubPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  featured: PropTypes.array,
}

const BookclubPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <div>
      <SEO uniqueTitle={frontmatter.title} uniqueDescription={frontmatter.description} />
      <Layout image={frontmatter.image} title={frontmatter.title}>
        <BookclubPageTemplate
          content={post.html}
          contentComponent={HTMLContent}
          featured={frontmatter.featured}
        />
      </Layout>
    </div>
  )
}

BookclubPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BookclubPage

export const galleryPageQuery = graphql`
  query BookclubPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featured {
          image {
            childImageSharp {
              fixed(height: 192, quality: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }  
      }
    }
  }
`
