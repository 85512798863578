import React from 'react'
import PropTypes from 'prop-types'
import PreviewImage from './PreviewImage'

const BookEntry = ({gridItems}) => (
 
    <div className="columns">
      {gridItems.map((item, index) => (
        <div key={index} className="p-3">
            <div
              style={{
                minWidth: '128px',
                display: 'inline-block',
              }}
            >
              <PreviewImage imageInfo={item} />
            </div>
        </div> 
      ))}
    </div>
)

BookEntry.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
}

export default BookEntry
